.gridCorporateNormalList .public_fixedDataTableCell_main,
.gridCorporateNormalList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

.gridCorporateNormalList .cancel:hover  {
    background-color: #919191
}
.gridCorporateNormalList .cancel  {
    background-color: #a9a9a9
}

.gridCorporateNormalList .normal:hover  {
    background-color: #ffecb9
}


.border-bottoms {
    border-bottom:thin solid #a3a0a0;
}
/* .react-grid-HeaderCell {
    padding: 0px;
} */
.react-grid-Cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align:left;
}
.react-grid-Cell img{height:60px;}

.roomname{
    display: inline-block;
    width: 100px;
    height:30px;
    border-bottom: solid 1px #666666;
    border-right: solid 1px   #666666;
    padding:3px;
}
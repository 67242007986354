.customer_duplicate {
    position: fixed;	/* 要素の位置を固定する */
    top: 50px;	/* 基準の位置を画面の一番下に指定する */
    right: 30px;	/* 基準の位置を画面の一番右に指定する */
    width: calc(100% - 50vw - 20px);;	/* 幅を指定する */
    height: calc(100% - 60px);;
  overflow: auto;
  z-index:1000;
  background:#FFFFFF;
    filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
    }
.gridCorporateSearchList .public_fixedDataTableCell_main,
.gridCorporateSearchList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

/* .gridCorporateSearchList .normal:hover  {
    background-color: #ffecb9
}

.gridCorporateSearchList .select  {
    background-color: #ffb9d4
}
.gridCorporateSearchList .select:hover  {
    background-color: #ffb9d4
} */


.gridCorporateSearchList .normal:hover  {
    background-color: #fdf1d1
}

.gridCorporateSearchList .select  {
    background-color: #fde091
}
.gridCorporateSearchList .select:hover  {
    background-color: #fde091
}



.gridAttentionNormalList .public_fixedDataTableCell_main,
.gridAttentionNormalList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}

.gridAttentionNormalList .normal:hover  {
    background-color: #fdf1d1
}

.gridAttentionNormalList .nikkei:hover  {
    background-color: #fda4a4
}
.gridAttentionNormalList .nikkei  {
    background-color: #ffb8b8
}
.gridTaskNormalList .public_fixedDataTableCell_main,
.gridTaskNormalList .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: inherit;
    
}
.fixedDataTableRowLayout_body{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background-color: inherit;
}
.fixedDataTableCellGroupLayout_cellGroup{
    background-color: inherit;
}

.gridTaskNormalList .completed:hover  {
    background-color: #afaeae
}
.gridTaskNormalList .completed  {
    background-color: #cccccc
}

.gridTaskNormalList .normal:hover  {
    background-color: #ffecb9
}

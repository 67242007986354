

.row-highlight>div>div{
    border-top:thin solid #808080;
    border-bottom:thin solid #808080;
     background-color:#f0fff0 !important;
       }
/* .react-grid-HeaderCell {
    padding: 0px;
} */
.react-grid-Cell {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align:left;
}
.react-grid-Cell img{height:60px;}
table {
    border: 1px solid #dcdcdc;
    width: 100%;
}

th {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: center;
    padding: 3px;
}

td {
    border: 1px solid #dcdcdc;
    height: 40px;
    text-align: left;
    padding: 3px;
}
.form-group {
    margin-bottom: 0px;
}

.disp_table th{
    height:28px;
    font-size: 13px;
}
.disp_table td{
    height:28px;
}

.basicList th, td{
    height:30px
}

/* .Select {
    width: 200px !important;
    margin-right: 5px;
    z-index: 0 !important;
    opacity:1;
}

.Select.is-open {
    z-index: 1 !important;
    opacity:1;
}


.Select-menu {
    position: fixed !important;
    width: 200px !important;
    margin: 1px;

}

.Select-menu-outer {
    z-index: 100 !important;

  } */

.rbc-event-label {
  display: none;
}

.rbc-event{
   /* border-radius: 4px !important;  */
   border: solid 2px #fff !important; 
}

.react-pdf__Page__canvas{
  display: inline !important; 
  border: 1px solid gray;
}

.selectFiles{
    padding:10px;
    cursor: pointer;
}
.selectFiles:hover{
    background-color: #CCC;
}
.middle{
    width:50%;
}
.w200 {
    width: 200px;
}

.w150i {
    width: 150px!important;
}

.w80 {
    width: 80px;
}

.zip_btn {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.5em;
    text-decoration: none;
    color: #FFF;
    background: #0064be; /*背景色*/
    border-bottom: solid 2px #0029be; /*少し濃い目の色に*/
    border-radius: 4px; /*角の丸み*/
    box-shadow: inset 0 2px 0 rgba(255,255,255,0.2), 0 2px 2px rgba(0, 0, 0, 0.19);
    font-weight: bold;
}

    .zip_btn:active { /*ボタンを押したとき*/
        border-bottom: solid 2px #0029be;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.30);
    }


.form-control {

}

.eq{
    display: inline-block;
    width:32%;
    padding: 0px;
    font-size:11px;
}
.eq label{
    margin:0;
}

.seldiv{
    display: inline-block;
    width:32%;
    padding: 0px;
    font-size:11px;
}

.seldiv label{
    margin:0;
}

.sellist{
    margin-left: 5px;
}


input[type="checkbox"]:checked + span{

    background-color:yellow;
}


.side_menu_title{
    font-weight: bold;
}

.searchtitle{
    font-weight: bold;
    float:left;
    width:70px;
    /* color: #FFFFFF; */
    /* clear: both; */
    /* background: #a9a9a9; */
    padding: 6px;
}
.searchform{
    width:230px;
    float:left;
    /* clear: both; */
    /* background: #f5f5f5; */
    padding: 6px;
}

.searchdiv:after {
    content: "";
    display: block;
    clear: both;
}

.dateform{
    width:100px
}

.search_menu{

}
.search_menu::-webkit-scrollbar {
    display: none;
  }

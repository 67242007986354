.stateCell{
    text-align: center;
    width: 42px;
    height: 40px;
    background-color: #ffffff;
}
/* .stateCell:hover {
    background-color: #ffc9d7;
} */


.stateCell.open,.stateCell.crowded,.stateCell.tel{
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all  0.3s ease;
    cursor: pointer;
}
.stateCell.open:hover,.stateCell.crowded:hover{
    background-color: #ffc9d7;
}

.stateCell span{
    display:block;
    padding-top:5px;
}
.open span{
    color:#CC4466;
    font-size: 18px;
}
.crowded span{
    color:#CC4466;
    font-size: 18px;
}
.tel span{
    color:#909000;
    font-size: 16px;
}
.closed{
    background-color: #EEEEEE;
}
.closed span{
    color:#333333;
    font-size: 20px;
}
.holiday{
    position: absolute;
    background-color: #EEEEEE;
}
.holiday span{
    color:#333333;
    position: relative;
    left: 5px;
    font-weight: 600;
    /* -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl; */
        letter-spacing: 10px;
}
@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?uyx1fe') format('truetype'),
    url('fonts/icomoon.woff?uyx1fe') format('woff'),
    url('fonts/icomoon.svg?uyx1fe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-building:before {
  content: "\e900";
}
.icon-contract:before {
  content: "\e901";
}
.icon-groupware:before {
  content: "\e902";
}
.icon-local:before {
  content: "\e903";
}
.icon-money:before {
  content: "\e904";
}
.icon-monthly_quick:before {
  content: "\e905";
}
.icon-move:before {
  content: "\e906";
}
.icon-owner:before {
  content: "\e907";
}
.icon-patrol:before {
  content: "\e908";
}
.icon-reform:before {
  content: "\e909";
}
.icon-rent:before {
  content: "\e90a";
}
.icon-sellbuy:before {
  content: "\e90b";
}
.icon-general:before {
  content: "\e9a4";
}
.icon-homestation:before {
  content: "\e800";
  color: #fff;
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-cog:before {
  content: "\e994";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-question:before {
  content: "\ea09";
}
.icon-loop:before {
  content: "\ea2d";
}


#contractfollow table{
    border:0px
}

#contractfollow td{
    border:0px
}
 #contractfollow .MuiCardContent-root:last-child {
    padding-bottom: 0px !important;
}
#contractfollow .MuiCardContent-root {
    padding: 0px !important;
} 
#contractfollow .MuiFormControl-marginNormal {
margin:0px !important;
}

